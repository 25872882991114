import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin, Video } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Steep cliffs drop hundreds of meters down into the dark water of the
Hardangerfjord. Is that terrain is any good for biking? William and Klara
must have thought the same, when they decided to move here and opened their
bike lodge and guiding business - `}<a parentName="p" {...{
        "href": "https://anyexcusetoride.com"
      }}>{`Any Excuse To Ride`}</a>{`.`}</p>
    <TrailguidePlugin content="lat=60.4782&lng=6.5887&coverage=15" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=10,60.4159,6.4984"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`Two years later, I am driving on a narrow road that crouches to the mountain,
fallen rocks left holes in the surface. After a corner a side valley opens up,
a few houses, a ferry port, a campsite that has seen better times, and at the
end of the village, the Solbakken Farm. A cozy wooden house at the bottom of
the cliffs that welcomes bikers and travelers.`}</p>
    <br />
    <br />
    <h4>{`Olavstøl`}</h4>
    <p>{`Biking in Hardangerfjord means views like this!
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3260"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/hardangerfjord_18.jpg" mdxType="Image" />
    <br />
    <h4>{`The Solbakken Farm`}</h4>
    <p>{`Since they arrived William and Klara put in an incredible amount of time
and effort to explore the mountains around the Hardangerfjord. Find ridable
trails, talk to land owners, authorities, clear existing trails and build new
ones.`}</p>
    <br />
    <p>{`They found nice and mellow terrain, open pine forests covered with a
carpet of saturated green moss. And they found long, alpine and technical
trails, high above the fjord. Not to forget about the two awesome trails
they built right behind their house! One of them is
`}<a parentName="p" {...{
        "href": "https://trailguide.net/4933"
      }}>{`Kvanndal Korners`}</a>{`:`}</p>
    <Video src="Wna3Ez-8aL4" mdxType="Video" />
    <Image src="destinations/hardangerfjord_22.jpg" mdxType="Image" />
    <br />
    <h4>{`Trailspotting`}</h4>
    <p>{`We leave the Solbakken Farm and Kvanndal with the ferry. The others talk
about the adventures of the last days. A long trail descents from the Oksen
down to the Fjord.`}</p>
    <Image src="destinations/hardangerfjord_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Kaiser Stien`}</h4>
    <p>{`But today we are on the other end of the Hardangerfjord near Odda. The Kaiser
Stien is one of those long alpine tracks.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/4934"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/hardangerfjord_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Kaiser Stien`}</h4>
    <p>{`After we carried the bikes for 1200 height meters, the trail starts below
the Folgefonna glacier. Rocks dominate the upper part.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/4934"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/hardangerfjord_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Eitrheim`}</h4>
    <p>{`Water is omnipresent. We follow the creek to the bottom. In Norway water is
often used to generate electricity, sometimes directly used for production.
On the other side of the valley you can see the entrance to the valley of
Trolltunga and Preikestolen. While thousands of selfies are taken there,
it's nice and quiet over here.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/4934"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/hardangerfjord_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Solbakken Farm`}</h4>
    <p>{`William and Klara offer complete holiday packages and we are treated with
a nice dinner.`}</p>
    <Image src="destinations/hardangerfjord_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Cat & Dog`}</h4>
    <p>{`Always around: CAT the cat and the NORA the trail dog.`}</p>
    <Image src="destinations/hardangerfjord_16.jpg" mdxType="Image" />
    <br />
    <h4>{`Shuttling`}</h4>
    <p>{`After the 1200 height meters with the bike on our shoulders yesterday, we are
very happy about some shuttle assistance.`}</p>
    <Image src="destinations/hardangerfjord_12.jpg" mdxType="Image" />
    <br />
    <h4>{`Trælavollen`}</h4>
    <p>{`Since there are so many steep and alpine trails, William and Klara started
to build their own trails. These handbuild trails are a lot more flowy
and play with the terrain.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/4935"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/hardangerfjord_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Dead Deer`}</h4>
    <p>{`The Dead Deer is candy for the eye and the soul of a mountain biker!
Loamy corners, carved into the soft dirt in an open forest with a carpet
of moss.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/4437"
      }}>{`here`}</a>{`.`}</p>
    <br />
    <p>{`One of the built trails that is still a secret - but William will show
you of course - is the `}<strong parentName="p">{`Lazy Donkey`}</strong>{`. Here, even the laziest donkey
will start to move and swing his ass around the trees, because this trail
is a hell of a fun:`}</p>
    <Video src="RHMIImWilNA" mdxType="Video" />
    <Image src="destinations/hardangerfjord_20.jpg" mdxType="Image" />
    <br />
    <h4>{`Not enough time`}</h4>
    <p>{`Too much trails to discover at once. But enough reasons to come back and
see more of William and Klaras Hardangerfjord.`}</p>
    <Image src="destinations/hardangerfjord_23.jpg" mdxType="Image" />
    <br />
    <h4>{`William & Klara`}</h4>
    <p>{`Thank you for some awesome days! We will come back for sure.`}</p>
    <Image src="destinations/hardangerfjord_24.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      